import "./App.scss";
import crewmate from "./crewmate.png";
import { useState, useEffect } from "react";
import BottomBar from "./BottomBar";
import Modal from "./Modal";

const App = () => {
  const [displayText, setDisplayText] = useState("");
  const [modal, setModal] = useState(false)

  const mobile = window.innerWidth < 700;
  const textDelay = mobile ? 3000 : 3500;

  const postText =
    window.location.pathname !== "/"
      ? ` ${window.location.pathname.split("-").join(" ").slice(1)}`
      : " was not The Impostor";
  const preText = window.location.hostname
    .split(".")[0].split('-')
    .map((word) => {
      if (word === 'wasnottheimpostor') return ''
      return word[0].toUpperCase() + word.slice(1);
    })
    .join(" ");

  const appText = `${preText} ${postText}.`;
  const numStars = mobile ? 30 : 100;
  const starArray = new Array(numStars).fill(".");

  useEffect(() => {
    appText.split("").forEach((letter, index) => {
      window.setTimeout(function () {
        setDisplayText(appText.slice(0, index));
      }, index * 100 + textDelay);
    });
  }, [appText, textDelay]);

  const toggleModal = () => setModal(!modal)

  return (
    <div className="App">
      <h1 id="text">{displayText}</h1>
      <div className="starContainer">
        {starArray.map((el) => (
          <div className="star"></div>
        ))}
      </div>
      <img id="crewMate" src={crewmate} alt="crewmate"></img>
      {modal && <Modal />}
      <BottomBar toggleModal={toggleModal} />
    </div>
  );
};

export default App;
