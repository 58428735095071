import React from 'react'

const Modal = () => {
  return (
    <div className="modal">

      <div>
        <p>Set the impostor by changing the url:</p>
        <a href="https://red.wasnottheimpostor.com">red.wasnottheimpostor.com</a>
      </div>

      <div>
        <p>Swap the rest of the text with the path:</p>
        <a href="https://red.wasnottheimpostor.com/is-sus">red.wasnottheimpostor.com/is-sus</a>
      </div>

      <div>
        <p>Separate words for the impostor and text by using "-"</p>
        <a href="https://red-and-green.wasnottheimpostor.com/were-not-the-impostors">red-and-green.wasnottheimpostor.com/were-not-the-impostors</a>
      </div>
      
      <p>Made for fun by <a href="https://arohan.me">this impostor</a>, with assets from <a href="https://www.reddit.com/r/AmongUs/comments/iut5y2/couldnt_find_many_pngs_of_the_among_us_characters/">here</a> and adapted <a href="https://codepen.io/alphardex/pen/dyPorwJ">css.</a> Based on <a href="http://www.innersloth.com/gameAmongUs.php">Among Us.</a></p>
    </div>
  )
}

export default Modal