import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

const BottomBar = (props) => {
  return (
    <div className="bottom-bar">
      <AiOutlineQuestionCircle onClick={() => props.toggleModal()}/>
    </div>
  )
}

export default BottomBar;